@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,200&family=Nunito:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  height: 100%;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color:#971d1d !important;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today{
    background: rgb(253, 226, 226) !important;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value{
    color: #971d1d !important;
}
.ant-radio-button-wrapper-checked{
    color: #971d1d !important;
    border-color: #971d1d  !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before{
    background-color: #971d1d !important;
}
.ant-tabs-ink-bar {
    position: absolute;
    background: #e40303 !important;
    pointer-events: none;
 }
.ant-tabs-tab-btn{
   font-size: 20px;
 }
 .ant-tabs-tab-btn:hover{
   color: #971d1d !important;
 }
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #e40303 !important; 
    font-weight: 500;
 }
.ant-tabs-tabpane{
   text-align: center;
}
.ant-btn:hover{
   color: #971d1d !important;
   border-color: #971d1d !important;
}
.ant-btn:focus{
   color: #e40303 !important;
   border-color: #e40303 !important;
   
}
.ant-btn-primary{
   border-color: #000000 !important;
   background: #ffffff !important;
   color: black !important;
}
.ant-input-affix-wrapper{
   border: 1px solid #971d1d !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
   background: #971d1d !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before{
   border: 1px solid #971d1d !important;
}
.login-form {
    max-width: 80%;
}
.login-form-forgot {
    float: right;
    color:azure;
}
.login-form-button {
    width: 100%;
    color:rgb(196, 101, 58);
}
.space {
    align-content: center;
    justify-content: center;
    width: 25%;
    height: 50%;
    position: relative;
    left: 10%;
    margin-top: 0%;
    border-radius: 10%;
    box-shadow: 5px 5px 5px;
    background-color: rgb(22, 22, 22);
}

.space div{
    margin-top: 1%;
    margin-left: 4%;
}
.title {
    font-size: 50px;
    font-family: 'Nunito', sans-serif;
    margin-left: 35%;
    color: azure;
}
.register {
    color: azure;
    margin-top: 3%;
    margin-left: 20%;
}
.registerinput {
    margin-bottom: 5%;
}
h1 {
    position: relative;
    top:0%;
    left:-10%;
    font-size: 100px;
    padding: 0;
    margin: 0;
    color: white;
    text-shadow: 0.5px 0.5px 0.5px;
    font-family: 'Josefin Sans',Arial, Helvetica, sans-serif;
    font-weight: 100;
}
@media(max-width: 1080px) {
    .login-form {
        max-width: 80%;
    }
    .login-form-forgot {
        float: right;
        color:azure;
    }
    .login-form-button {
        width: 100%;
        color:rgb(196, 101, 58);
    }
    .space {
        align-content: center;
        justify-content: center;
        width: 35%;
        height: 50%;
        position: relative;
        left: 12%;
        margin-top: 0%;
        border-radius: 10%;
        box-shadow: 5px 5px 5px;
        background-color: rgb(22, 22, 22);
    }
    
    .space div{
        margin-top: 1%;
        margin-left: 4%;
    }
    
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
    .title {
        font-size: 50px;
        font-family: 'Nunito', sans-serif;
        margin-left: 18%;
        color: azure;
    }
    .register {
        color: azure;
        margin-top: 3%;
        margin-left: 20%;
    }
    .registerinput {
        margin-bottom: 5%;
    }
    @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,200&family=Nunito:wght@500&display=swap');
    h1 {
        position: relative;
        top:0%;
        left:-10%;
        font-size: 50px;
        padding: 0;
        margin: 0;
        color: white;
        text-shadow: 0.5px 0.5px 0.5px;
        font-family: 'Josefin Sans',Arial, Helvetica, sans-serif;
        font-weight: 100;
    }
}
.bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom right,  
      #2C3E50, #FD746C, 
      #FF8235, #ffff1c, 
      #92FE9D, #00C9FF, 
      #a044ff, #e73827);
    background-repeat: no-repeat;
    background-size: 1000% 1000%;
    -webkit-animation: gradient 120s ease infinite;
            animation: gradient 120s ease infinite;
  }
  @-webkit-keyframes gradient { 
    0%{background-position:0% 0%}
    50%{background-position:100% 100%}
    100%{background-position:0% 0%}
}
  @keyframes gradient { 
    0%{background-position:0% 0%}
    50%{background-position:100% 100%}
    100%{background-position:0% 0%}
}
.back{
    height: 789px;
}
body {
    height: 100%;
}



.pie{
    width: 100%;
    height:100%;
    margin-left: 31%;
}
@media(max-width: 920px) {
    .pie{
        width: 100%;
        height:100%;
        margin-left: 14%;
    }
}

@media(max-width: 768px) {
    .pie{
        width: 100%;
        height:100%;
        margin-left: 4%;
    }
}

@media(max-width: 540px) {
    .pie{
        width: 100%;
        height:100%;
        margin-left: 1%;
    }
}
.ant-picker{
    border:1px solid black !important;
}
.ant-menu-item-selected {
    background-color: #e40303 !important;
  }
.ant-layout{
  background: #ffffff !important;
}
