.pie{
    width: 100%;
    height:100%;
    margin-left: 31%;
}
@media(max-width: 920px) {
    .pie{
        width: 100%;
        height:100%;
        margin-left: 14%;
    }
}

@media(max-width: 768px) {
    .pie{
        width: 100%;
        height:100%;
        margin-left: 4%;
    }
}

@media(max-width: 540px) {
    .pie{
        width: 100%;
        height:100%;
        margin-left: 1%;
    }
}
.ant-picker{
    border:1px solid black !important;
}