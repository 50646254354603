.login-form {
    max-width: 80%;
}
.login-form-forgot {
    float: right;
    color:azure;
}
.login-form-button {
    width: 100%;
    color:rgb(196, 101, 58);
}
.space {
    align-content: center;
    justify-content: center;
    width: 25%;
    height: 50%;
    position: relative;
    left: 10%;
    margin-top: 0%;
    border-radius: 10%;
    box-shadow: 5px 5px 5px;
    background-color: rgb(22, 22, 22);
}

.space div{
    margin-top: 1%;
    margin-left: 4%;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
.title {
    font-size: 50px;
    font-family: 'Nunito', sans-serif;
    margin-left: 35%;
    color: azure;
}
.register {
    color: azure;
    margin-top: 3%;
    margin-left: 20%;
}
.registerinput {
    margin-bottom: 5%;
}
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,200&family=Nunito:wght@500&display=swap');
h1 {
    position: relative;
    top:0%;
    left:-10%;
    font-size: 100px;
    padding: 0;
    margin: 0;
    color: white;
    text-shadow: 0.5px 0.5px 0.5px;
    font-family: 'Josefin Sans',Arial, Helvetica, sans-serif;
    font-weight: 100;
}
@media(max-width: 1080px) {
    .login-form {
        max-width: 80%;
    }
    .login-form-forgot {
        float: right;
        color:azure;
    }
    .login-form-button {
        width: 100%;
        color:rgb(196, 101, 58);
    }
    .space {
        align-content: center;
        justify-content: center;
        width: 35%;
        height: 50%;
        position: relative;
        left: 12%;
        margin-top: 0%;
        border-radius: 10%;
        box-shadow: 5px 5px 5px;
        background-color: rgb(22, 22, 22);
    }
    
    .space div{
        margin-top: 1%;
        margin-left: 4%;
    }
    
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
    .title {
        font-size: 50px;
        font-family: 'Nunito', sans-serif;
        margin-left: 18%;
        color: azure;
    }
    .register {
        color: azure;
        margin-top: 3%;
        margin-left: 20%;
    }
    .registerinput {
        margin-bottom: 5%;
    }
    @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,200&family=Nunito:wght@500&display=swap');
    h1 {
        position: relative;
        top:0%;
        left:-10%;
        font-size: 50px;
        padding: 0;
        margin: 0;
        color: white;
        text-shadow: 0.5px 0.5px 0.5px;
        font-family: 'Josefin Sans',Arial, Helvetica, sans-serif;
        font-weight: 100;
    }
}
.bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom right,  
      #2C3E50, #FD746C, 
      #FF8235, #ffff1c, 
      #92FE9D, #00C9FF, 
      #a044ff, #e73827);
    background-repeat: no-repeat;
    background-size: 1000% 1000%;
    animation: gradient 120s ease infinite;
  }
  @keyframes gradient { 
    0%{background-position:0% 0%}
    50%{background-position:100% 100%}
    100%{background-position:0% 0%}
}
.back{
    height: 789px;
}
body {
    height: 100%;
}


